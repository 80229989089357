var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('modal',{ref:"modalCambiarCedis",attrs:{"titulo":"Editar horario de atención","tamano":"modal-lg","no-aceptar":"","adicional":_vm.bottom},on:{"adicional":_vm.postFranjas}},[(_vm.cargando)?_c('cargando'):_vm._e(),_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"row mx-0 mt-3 align-items-center"},[_c('div',{staticClass:"col-auto px-0"},[_c('p',{staticClass:"text-general f-14"},[_vm._v(" Horas mínimo de "),_c('br'),_vm._v(" programación del pedido ")])]),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric","name":"Horas mínimo de programación del pedido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Horas","size":"small"},on:{"blur":function($event){return _vm.changeParametros('franja_programacion', 'horas')}},model:{value:(_vm.horas),callback:function ($$v) {_vm.horas=$$v},expression:"horas"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-auto px-0 ml-4"},[_c('p',{staticClass:"text-general f-14"},[_vm._v(" Días máximo de "),_c('br'),_vm._v(" programación del pedido ")])]),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric","name":"Días máximo de programación del pedido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Días","size":"small"},on:{"blur":function($event){return _vm.changeParametros('franja_maximo', 'dias')}},model:{value:(_vm.dias),callback:function ($$v) {_vm.dias=$$v},expression:"dias"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])]),_c('div',{staticClass:"row mx-0 mt-4 tab-filter border-bottom mb-4"},_vm._l((_vm.diasSemana),function(dia,index){return _c('div',{key:index,class:("filter-space" + (_vm.diaSelect == dia.id ? '-active' : '') + " px-3 cr-pointer"),on:{"click":function($event){_vm.diaSelect = dia.id}}},[(!dia.activo)?_c('div',{staticClass:"point-red"}):_vm._e(),_vm._v(" "+_vm._s(dia.dia)+" ")])}),0),_c('section',{staticClass:"horario-cedis pl-3 mb-5"},[_vm._l((_vm.horarios[_vm.diaSelect]),function(data,index){return _c('div',{key:index,staticClass:"row mx-0 align-items-center mb-3"},[_c('div',{staticClass:"col-3"},[_c('el-time-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Apertura","picker-options":{
                        start: '05:00',
                        step: '00:30',
                        end: '23:59',
                        format: 'hh:mm a',
                        valueFormat:'HH:mm',
                        minTime: _vm.validateTime('apertura',index)
                    }},model:{value:(data.apertura),callback:function ($$v) {_vm.$set(data, "apertura", $$v)},expression:"data.apertura"}})],1),_c('div',{staticClass:"col-auto px-1 f-14 text-general f-600"},[_vm._v(" - ")]),_c('div',{staticClass:"col-3"},[_c('el-time-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Cierre","picker-options":{
                        start: '05:00',
                        step: '00:30',
                        end: '23:59',
                        format: 'hh:mm a',
                        valueFormat:'HH:mm',
                        minTime: _vm.validateTime('cierre',index)
                    }},model:{value:(data.cierre),callback:function ($$v) {_vm.$set(data, "cierre", $$v)},expression:"data.cierre"}})],1),_c('el-tooltip',{attrs:{"placement":"bottom","content":"Eliminar","effect":"light"}},[(_vm.horarios[_vm.diaSelect].length > 1)?_c('i',{staticClass:"icon-delete-trash f-18 text-general cr-pointer",on:{"click":function($event){return _vm.deleteHorario(data.id, index)}}}):_vm._e()])],1)}),_c('div',{staticClass:"row mx-0 align-items-center mt-4"},[_c('div',{staticClass:"col-auto text-general-red f-500 mr-3 f-14 cr-pointer border br-5",on:{"click":_vm.pushHorario}},[_vm._v(" Agregar horario ")]),_c('el-checkbox',{staticClass:"check-green",on:{"change":function($event){return _vm.changeStatus($event)}},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(" Inactivar ")])],1)],2),_c('div',{staticClass:"row mx-0 align-items-center mt-4 pl-3 mb-5"},[_c('div',{staticClass:"col-auto text-general f-500 mr-3 f-14 cr-pointer border br-5",on:{"click":_vm.restablecerConfiguracion}},[_vm._v(" Restaurar al default ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }