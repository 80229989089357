<template>
    <section>
        <modal ref="modalCambiarCedis" titulo="Editar horario de atención" tamano="modal-lg" no-aceptar :adicional="bottom" @adicional="postFranjas">
            <cargando v-if="cargando" />
            <ValidationObserver ref="validacion">
                <div class="row mx-0 mt-3 align-items-center">
                    <div class="col-auto px-0">
                        <p class="text-general f-14">
                            Horas mínimo de <br /> programación del pedido
                        </p>
                    </div>
                    <div class="col-2">
                        <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Horas mínimo de programación del pedido">
                            <el-input v-model="horas" placeholder="Horas" class="w-100" size="small" @blur="changeParametros('franja_programacion', 'horas')" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto px-0 ml-4">
                        <p class="text-general f-14">
                            Días máximo de <br /> programación del pedido
                        </p>
                    </div>
                    <div class="col-2">
                        <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Días máximo de programación del pedido">
                            <el-input v-model="dias" placeholder="Días" class="w-100" size="small" @blur="changeParametros('franja_maximo', 'dias')" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </validationobserver>
            <div class="row mx-0 mt-4 tab-filter border-bottom mb-4">
                <div v-for="(dia, index) in diasSemana" :key="index" :class="`filter-space${diaSelect == dia.id ? '-active' : ''} px-3 cr-pointer`" @click="diaSelect = dia.id">
                    <div v-if="!dia.activo" class="point-red" />
                    {{ dia.dia }}
                </div>
            </div>
            <section class="horario-cedis pl-3 mb-5">
                <div v-for="(data, index) in horarios[diaSelect]" :key="index" class="row mx-0 align-items-center mb-3">
                    <div class="col-3">
                        <el-time-select
                        v-model="data.apertura"
                        size="small"
                        placeholder="Apertura"
                        class="w-100"
                        :picker-options="{
                            start: '05:00',
                            step: '00:30',
                            end: '23:59',
                            format: 'hh:mm a',
                            valueFormat:'HH:mm',
                            minTime: validateTime('apertura',index)
                        }"
                        />
                    </div>
                    <div class="col-auto px-1 f-14 text-general f-600">
                        -
                    </div>
                    <div class="col-3">
                        <el-time-select
                        v-model="data.cierre"
                        size="small"
                        placeholder="Cierre"
                        class="w-100"
                        :picker-options="{
                            start: '05:00',
                            step: '00:30',
                            end: '23:59',
                            format: 'hh:mm a',
                            valueFormat:'HH:mm',
                            minTime: validateTime('cierre',index)
                        }"
                        />
                    </div>
                    <el-tooltip placement="bottom" content="Eliminar" effect="light">
                        <i v-if="horarios[diaSelect].length > 1" class="icon-delete-trash f-18 text-general cr-pointer" @click="deleteHorario(data.id, index)" />
                    </el-tooltip>
                </div>
                <div class="row mx-0 align-items-center mt-4">
                    <div class="col-auto text-general-red f-500 mr-3 f-14 cr-pointer border br-5" @click="pushHorario">
                        Agregar horario
                    </div>
                    <el-checkbox v-model="checked" class="check-green" @change="changeStatus($event)">
                        Inactivar
                    </el-checkbox>
                </div>
            </section>
            <div class="row mx-0 align-items-center mt-4 pl-3 mb-5">
                <div class="col-auto text-general f-500 mr-3 f-14 cr-pointer border br-5" @click="restablecerConfiguracion">
                    Restaurar al default
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Service from "~/services/tiendas/cambio_datos";
import ServiceConfig from '@/services/configurar/admin/cedisHorarios'

export default {
    data(){
        return{
            cargando: false,
            checked: false,
            diaSelect: 1,
            nombreDiaSelect: 'Lunes',
            horas: '',
            dias: '',
            diasSemana: [],
            horarios: {},
        }
    },
    computed:{
        idTienda(){
            return Number(this.$route.params.id_tienda)
        },
        bottom(){
            return 'Actualizar horarios del cedis para el día '+this.nombreDiaSelect;
        }
    },
    watch:{
        diaSelect(){
            this.changeDay();
        },
    },
    methods: {
        toggle(franjas){
            this.iniciarValoresPredeterminados();
            this.asignarDias(franjas.parametros.dias_trabajados);
            this.horas = franjas.parametros.franja_programacion;
            this.dias = franjas.parametros.franja_maximo;
            if(franjas.soloHorarios.length > 0){
                this.asignarFranjas(franjas.soloHorarios);
            }
            this.$refs.modalCambiarCedis.toggle();
        },

        async changeDay(){
            this.isInactive();
            this.identificarDia();
        },

        async asignarFranjas(franjas){
            let contador = franjas.length;
            for(let i = 0; i < contador; i++){
                let horario = {};
                horario.id = franjas[i].id;
                horario.apertura = franjas[i].inicio;
                horario.cierre = franjas[i].fin;
                this.horarios[franjas[i].dia].push(horario);
                _.remove(this.horarios[franjas[i].dia], function(n){
                    return n.id === null
                });
            }
        },

        async postFranjas(){
            try {
                let valid = this.horarios[this.diaSelect].filter((item) => item.apertura == null || item.cierre == null).length;
                if(valid > 0)return this.notificacion('Mensaje','Campos, apertura y cierre no pueden ir vacíos','warning');
                const model = {
                    idTienda: this.idTienda,
                    dia: this.diaSelect,
                    franjas: this.horarios[this.diaSelect],
                };

                const {data} = await Service.postFranjas(model);

                if(data.exito){
                    this.horarios[this.diaSelect] = data.new;
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.$emit('actualizar');
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        async changeParametros(column, bandera){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;

                const model = {
                    column: column,
                    value:  bandera == 'horas' ? this.horas : this.dias
                };

                const {data} = await Service.putTiendasParametros(this.idTienda, model);

                if(data.exito){
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.$emit('actualizar');
                }

            } catch(e){
                this.error_catch(e)
            }
        },

        pushHorario(){
            let data = {};
            data.id = null;
            data.apertura = null;
            data.cierre = null;
            this.horarios[this.diaSelect].push(data);
        },

        async deleteHorario(idFranja, index){
            if(this.horarios[this.diaSelect].length == 1)return
            this.horarios[this.diaSelect].splice(index, 1);
            if(idFranja){
                try{
                    const {data} = await ServiceConfig.deleteFranja(idFranja);
                    this.$emit('actualizar');
                }catch(e){
                    this.error_catch(e)
                }
            }
            this.notificacion('', 'Horario eliminado', 'success');
        },

        async restablecerConfiguracion(){
            try {
                this.cargando = true;
                const {data} = await Service.defaultFranjas(this.idTienda);
                if(data.exito){
                    this.iniciarValoresPredeterminados();
                    this.asignarFranjas(data.franjas);
                    this.notificacion('Mensaje', 'Se ha restaurado la configuración correctamente', 'success');
                    this.$emit('actualizar');
                }
            } catch(e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },

        changeStatus(event){
            if(event){
                this.diasSemana[this.diaSelect - 1].activo = false;
            }else{
                this.diasSemana[this.diaSelect - 1].activo = true;
            }
            this.updateStatus();
        },

        async updateStatus(){
            try {
                let dias = [];
                dias = _.map(this.diasSemana, 'activo');

                dias[0] = dias[0] ? 1 : 0;
                dias[1] = dias[1] ? 1 : 0;
                dias[2] = dias[2] ? 1 : 0;
                dias[3] = dias[3] ? 1 : 0;
                dias[4] = dias[4] ? 1 : 0;
                dias[5] = dias[5] ? 1 : 0;
                dias[6] = dias[6] ? 1 : 0;
            
                const model = {
                    value: JSON.stringify(dias)
                };
                const {data} = await Service.putTiendaHorario(this.idTienda, model);
                if(data.exito){
                    this.notificacion('', 'El estado del día se ha actualizado', 'success');
                    this.$emit('actualizar');
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        isInactive(){
            let inactive = this.diasSemana.find((item) => item.id === this.diaSelect && item.activo);
            if(inactive){
                return this.checked = false;
            }
            this.checked = true;
        },
        
        async iniciarValoresPredeterminados(){
            this.horarios = {
                1:[{ id: null, apertura: null, cierre: null }],
                2:[{ id: null, apertura: null, cierre: null }],
                3:[{ id: null, apertura: null, cierre: null }],
                4:[{ id: null, apertura: null, cierre: null }],
                5:[{ id: null, apertura: null, cierre: null }],
                6:[{ id: null, apertura: null, cierre: null }],
                7:[{ id: null, apertura: null, cierre: null }],
            };

            this.diasSemana = [
                { id: 1, dia: 'Lunes', activo: true },
                { id: 2, dia: 'Martes', activo: true },
                { id: 3, dia: 'Miercoles', activo: true },
                { id: 4, dia: 'Jueves', activo: true },
                { id: 5, dia: 'Viernes', activo: true },
                { id: 6, dia: 'Sabado', activo: true },
                { id: 7, dia: 'Domingo', activo: true }
            ];
        },

        asignarDias(dias){
            dias = JSON.parse(dias);
            for(let i = 0; i < dias.length; i++){
                this.diasSemana[i].activo = dias[i] == 1 ? true : false;
            }
        },

        identificarDia(){
            switch(parseInt(this.diaSelect)){
            case 1:
                return this.nombreDiaSelect = 'Lunes';
            case 2:
                return this.nombreDiaSelect = 'Martes';
            case 3:
                return this.nombreDiaSelect = 'Miercoles';
            case 4:
                return this.nombreDiaSelect = 'Jueves';
            case 5:
                return this.nombreDiaSelect = 'Viernes';
            case 6:
                return this.nombreDiaSelect = 'Sabado';
            case 7:
                return this.nombreDiaSelect = 'Domingo';
            }
        },

        validateTime(value, index){
            let horarios = this.horarios[this.diaSelect];
            if(index != 0){
                if(value === 'apertura'){
                    return horarios[index-1].cierre;
                } else {
                    return horarios[index].apertura;
                }
            }
            if(value === 'cierre'){
                return horarios[index].apertura;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.tab-filter{
    .filter-space{
        min-width: 81px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        color: #637381;
        font-size: 14px;
        .point-red{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 11px;
            left: 1px;
            position: absolute;
            background-color: #FF1720;
        }
        &-active{
            @extend .filter-space;
            color: var(--color-general);
            border-bottom: 3px solid var(--color-general);
        }
    }
}
</style>
